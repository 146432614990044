@font-face {
  font-family: "Mabry Pro";
  src: local("Mabry Pro Regular"), local("Mabry-Pro-Regular"),
    url("./MabryPro-Regular.woff2") format("woff2"),
    url("./MabryPro-Regular.woff") format("woff"),
    url("./MabryPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mabry Pro Bold";
  src: local("Mabry Pro Bold"), local("Mabry-Pro-Bold"),
    url("./MabryPro-Bold.woff2") format("woff2"),
    url("./MabryPro-Bold.woff") format("woff"),
    url("./MabryPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mabry Pro Light";
  src: local("Mabry Pro Light"), local("Mabry-Pro-Light"),
    url("MabryPro-Light.woff2") format("woff2"),
    url("MabryPro-Light.woff") format("woff"),
    url("MabryPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

.font-mabry {
  font-family: "Mabry Pro", sans-serif;
}

.font-mabry-bold {
  font-family: "Mabry Pro Bold", sans-serif;
}

.font-mabry-light {
  font-family: "Mabry Pro Light", sans-serif;
}
