@import url("./fonts/style.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
html {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background: #f7f7f7;
}

.footer-card-h5 {
  background: url("./assets/images/trade-vec.png");
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply the animation */
.slide-up {
  animation: slideUp 1s ease-in-out forwards;
}
